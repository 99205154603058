export function changeOrder (array, order) {
	// if we go up
	const newOrder = { ...order };
	if (order.newIndex < order.oldIndex) {
		newOrder.newIndex = order.oldIndex;
		newOrder.oldIndex = order.newIndex;
	}

	const newArray = [...array];

	let swap = newArray.splice(newOrder.oldIndex, 1);
	newArray.splice(newOrder.newIndex, 0, swap[0]);
	if (newOrder.swap === true) {
		swap = newArray.splice(newOrder.newIndex - 1, 1);
		newArray.splice(newOrder.oldIndex, 0, swap[0]);
	}

	return newArray;
}
