const moment = require('moment');
const { ClipboardItem } = window;


export async function copyImageToClipboard (blob) {
	try {
		await requestClipboardWritePermission();
	} catch (err) {}
	try {
		const clipboardItem = new ClipboardItem({ 'image/png': blob });
		await navigator.clipboard.write([clipboardItem]);
	} catch (err) {
		console.log(err);

		// Download the file if the clipboard does not work
		const file = new Blob([blob], { type: 'image/png' });
		const fileURL = URL.createObjectURL(file);
		const fileLink = document.createElement('a');
		fileLink.href = fileURL;
		fileLink.setAttribute('download', ['talking-point-export', moment().format('MM-DD-YYYY')].join('-'));
		document.body.appendChild(fileLink);

		fileLink.click();
	}
}


export async function requestClipboardWritePermission () {
	if (!navigator?.permissions?.query) return false;

	const { state } = await navigator.permissions.query({
		name: 'clipboard-write'
	});

	return state === 'granted';
}
