import VueGtag from 'vue-gtag';
import router from '@/router';

export default {
	async install (Vue, options) {
		const analyticsFile = await fetch('/config/analytics.json');
		const analyticsJson = await analyticsFile.json();
		Vue.use(VueGtag, {
			config: { id: analyticsJson.API_KEY }
		}, router);
		Vue.prototype.$gaApiKey = analyticsJson.API_KEY;
	}
};
