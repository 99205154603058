import Vue from 'vue';
import Vuex from 'vuex';

import general from './general';
import surveys from './surveys';
import talkingPoints from './talkingPoints';
import report from './report';
import contentLibrary from './contentLibrary';
import userSettings from './userSettings';
import home from './home';
import analytics from './analytics';


Vue.use(Vuex);


export default new Vuex.Store({
	modules: {
		analytics,
		general,
		surveys,
		talkingPoints,
		report,
		contentLibrary,
		userSettings,
		home
	}
});
