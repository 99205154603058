import router from '@/router';
const api = require('@/utils/api');
const { formatSurveyWithWave } = require('@/utils/data');
const { RECENT_DOCUMENT_TYPES, TRENDING_TYPES } = require('@/constants/types');

const state = () => ({
	allRecentDocuments: [],
	allTrendingTopics: [],
	newestCollaterals: []
});

const mutations = {
	setDocuments (state, allRecentDocuments) {
		state.allRecentDocuments = allRecentDocuments;
	},
	setTrendingTopics (state, topics) {
		state.allTrendingTopics = topics;
	},
	setNewestCollaterals (state, files) {
		state.newestCollaterals = files;
	}
};

const actions = {
	async loadRecentDocuments ({ state, commit }) {
		commit('setDocuments', await api.loadUserRecentDocuments([
			RECENT_DOCUMENT_TYPES.SURVEY,
			RECENT_DOCUMENT_TYPES.COLLATERAL,
			RECENT_DOCUMENT_TYPES.REPORT
		]));
	},
	async loadMarketTrendings ({ state, commit }) {
		const documents = await api.loadMarketTrendings([TRENDING_TYPES.TOPIC]) || [];

		commit('setTrendingTopics', documents);
	},
	async loadNewestCollaterals ({ commit, rootGetters }) {
		const files = await api.loadCollateralMethodologyFiles({
			types: ['Collateral'],
			countries: [rootGetters['general/userMarket']],
			limit: 6,
			includesGlobalFiles: true
		}) || [];

		commit('setNewestCollaterals', files);
	},

	async addRecentDocument ({ state, commit }, { type, id }) {
		await api.addRecentDocument(type, id);
	},
	async addRecentSurveyDocument ({ state, commit, dispatch }, id) {
		await dispatch('addRecentDocument', { type: RECENT_DOCUMENT_TYPES.SURVEY, id });
	},
	async addRecentCollateralDocument ({ state, commit, dispatch }, id) {
		await dispatch('addRecentDocument', { type: RECENT_DOCUMENT_TYPES.COLLATERAL, id });
	},
	async addRecentMethodologyDocument ({ state, commit, dispatch }, id) {
		await dispatch('addRecentDocument', { type: RECENT_DOCUMENT_TYPES.METHODOLOGY, id });
	},
	async addRecentReportDocument ({ state, commit, dispatch }, id) {
		await dispatch('addRecentDocument', { type: RECENT_DOCUMENT_TYPES.REPORT, id });
	},

	onSelectPreviewFromHome ({ dispatch }, file) {
		dispatch('contentLibrary/onSelectPreview', file, { root: true });
		router.push({ path: '/content-library' });
	}
};

const getters = {
	allRecentDocuments (state) {
		return state.allRecentDocuments
			.slice(0, 12)
			.map(data => {
				const document = data.type === RECENT_DOCUMENT_TYPES.SURVEY ? formatSurveyWithWave(data.document) : data.document;

				return {
					...data,
					document
				};
			});
	},
	recentSurveysDocuments: (state) => (limit = undefined) => {
		return state.allRecentDocuments
			.filter(document => document.type === RECENT_DOCUMENT_TYPES.SURVEY)
			.slice(0, limit)
			.map(data => ({
				...data,
				document: formatSurveyWithWave(data.document)
			}));
	},
	recentCollateralsDocuments: (state) => (limit = undefined) => {
		return state.allRecentDocuments
			.filter(document => document.type === RECENT_DOCUMENT_TYPES.COLLATERAL)
			.slice(0, limit);
	},
	recentReportsDocuments (state) {
		return state.allRecentDocuments
			.filter(document => document.type === RECENT_DOCUMENT_TYPES.REPORT)
			.slice(0, 10);
	},

	mostTrendingTopics (state) {
		return state.allTrendingTopics.slice(0, 6);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
