import Vue from 'vue';
import VueRouter from 'vue-router';


Vue.use(VueRouter);


const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
	},
	{
		path: '/build-experience',
		name: 'BuildExperience',
		component: () => import(/* webpackChunkName: "build-experience" */ '../views/BuildExperience.vue')
	},
	{
		path: '/content-library',
		name: 'ContentLibrary',
		component: () => import(/* webpackChunkName: "content-library" */ '../views/ContentLibrary.vue')
	},
	{
		path: '/favorites',
		name: 'Favorites',
		component: () => import(/* webpackChunkName: "favorites" */ '../views/Favorites.vue')
	},
	{
		path: '/user-settings',
		name: 'UserSettings',
		component: () => import(/* webpackChunkName: "user-settings" */ '../views/UserSettings.vue')
	},
	{
		path: '/user-guide',
		name: 'User guide',
		component: () => import(/* webpackChunkName: "user guide" */ '../views/UserGuide.vue')
	},
	{
		path: '/frequently-asked-questions',
		name: 'FAQ',
		component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue')
	},
	{
		path: '/site-analytics',
		name: 'Site analytics',
		component: () => import(/* webpackChunkName: "site-analytics" */ '../views/Analytics.vue')
	},
	{
		path: '/generate',
		name: 'Generate',
		component: () => import(/* webpackChunkName: "generate" */ '../views/Generate.vue')
	}
];


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});


export default router;
