const api = require('@/utils/api');

export const STEPS = {
	SURVEYS: 'SURVEYS',
	TALKING_POINTS: 'TALKING_POINTS',
	REPORT: 'REPORT'
};

const state = () => ({
	user: {},
	userLocationUpdateStatus: '',
	userAvatarUpdateStatus: '',
	userAvatarUpdateError: '',

	disclaimers: {
		login: true,
		buildExperience: true
	},

	searchSynonyms: [],
	equivalentFilters: {},

	isFooterShown: true,

	activeStep: STEPS.SURVEYS,
	previousStep: null
});

const getters = {
	marketSynonyms (state) {
		return state.searchSynonyms.filter((item) => item.type === 'M');
	},
	respondentTypeSynonyms (state) {
		return state.searchSynonyms.filter((item) => item.type === 'R');
	},
	topicSynonyms (state) {
		return state.searchSynonyms.filter((item) => item.type === 'T');
	},

	userFirstname (state) {
		return state.user.name?.split(' ')[0] || state.user.name;
	},
	userMarket (state) {
		return state.user?.market || {};
	}
};

const mutations = {
	closeDisclaimer (state, disclaimerName) {
		state.disclaimers[disclaimerName] = false;
	},

	setUser (state, user) {
		state.user = user;
	},
	setUserLocationUpdateStatus (state, data) {
		state.userLocationUpdateStatus = data;
	},
	setUserAvatarUpdateStatus (state, data) {
		state.userAvatarUpdateStatus = data;
	},
	setUserAvatarUpdateError (state, data) {
		state.userAvatarUpdateError = data;
	},

	setSearchSynonyms (state, data) {
		state.searchSynonyms = data;
	},
	setEquivalentFilters (state, data) {
		state.equivalentFilters = data;
	},

	setIsFooterShown (state, data) {
		state.isFooterShown = data;
	},

	changeStep (state, step) {
		state.previousStep = state.activeStep;
		state.activeStep = step;
	},
	resetStep (state) {
		state.activeStep = STEPS.SURVEYS;
		state.previousStep = null;
	}
};

const actions = {
	async loadUserInfos ({ commit }) {
		commit('setUser', await api.userInfo());
	},
	async updateUserLocation ({ state, commit }, market) {
		commit('setUserLocationUpdateStatus', '');
		try {
			const result = await api.updateUserLocation(market);
			if (result.ok) {
				commit('setUser', { ...state.user, market: result.updated.market });
				commit('setUserLocationUpdateStatus', 'success');
			} else {
				commit('setUserLocationUpdateStatus', 'failed');
			}
		} catch (e) {
			commit('setUserLocationUpdateStatus', 'failed');
		}
	},
	async updateUserAvatar ({ state, commit }, image) {
		commit('setUserAvatarUpdateStatus', '');
		try {
			const result = await api.updateUserAvatar(image);
			if (result.ok) {
				commit('setUser', { ...state.user, image: result.updated.image });
				commit('setUserAvatarUpdateStatus', 'success');
			} else {
				commit('setUserAvatarUpdateStatus', 'failed');
			}
		} catch (e) {
			commit('setUserAvatarUpdateStatus', 'failed');

		}
	},

	async loadSearchData ({ commit }) {
		const searchData = await api.loadSearchData();
		if (searchData) {
			commit('setSearchSynonyms', searchData.synonyms.map(e => helperMethods.arrangeSynonym(e)));
		}
	},
	async loadEquivalentFilters ({ commit }) {
		commit('setEquivalentFilters', await api.loadEquivalentFilters());
	}
};

const helperMethods = {
	arrangeSynonym (synonymObj) {
		const { filter, id, countryName, countryCode, type, synonym } = synonymObj;
		return {
			filter: {
				label: filter,
				id,
				...(countryName !== undefined) && { countryName },
				...(countryCode) && { countryCode }
			},
			type,
			synonym
		};
	}

};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
