const axios = require('axios');

const apiPath = '/api/v1/';


async function ping () {
	const response = await axios.get(`${apiPath}ping`);
	return response?.data;
}


async function login (username, password) {
	const response = await axios.post('/security/login', { username, password });
	return response?.data;
}


async function userInfo () {
	const response = await axios.get('/security/user');
	return response?.data;
}

async function updateUserLocation (market) {
	const response = await axios.put('/security/user', { market });
	return response?.data;
}

async function updateUserAvatar (file) {
	const data = new FormData();
	data.append('avatar', file, file.name);
	const response = await axios.put('/security/user', data);
	return response?.data;
}

async function loadUserRecentDocuments (types) {
	const response = await axios.get(`${apiPath}user/recent-documents?types=${types}`);
	return response?.data;
}

async function addRecentDocument (type, id) {
	const response = await axios.put(`${apiPath}user/recent-documents`, { type, id });
	return response?.data;
}

async function loadMarketTrendings (types) {
	const response = await axios.get(`${apiPath}user/market-trendings?types=${types}`);
	return response?.data;
}

async function loadLastFavorites () {
	const response = await axios.get(`${apiPath}favorites/last-saved`);
	return response?.data;
}

async function loadUserGuide () {
	const response = await axios.get(`${apiPath}user-guide`);
	return response?.data;
}

async function loadCountries () {
	const response = await axios.get(`${apiPath}markets?type=country`);
	return response?.data;
}

async function loadAvailableFilters (selectedFilters) {
	const response = await axios.post(`${apiPath}availableFilters`, selectedFilters);
	return response?.data;
}

async function loadEquivalentFilters () {
	const response = await axios.get(`${apiPath}equivalentFilters`);
	return response?.data;
}


async function loadSurveyInfos (surveyId) {
	const response = await axios.get(`${apiPath}surveys/${surveyId}/infos`);
	return response?.data;
}


async function loadSurveys (filters) {
	const response = await axios.post(`${apiPath}surveys`, filters);
	return response?.data;
}


async function loadSearchData () {
	const response = await axios.get(`${apiPath}search/data`);
	return response?.data;
}


async function loadSurveySelects (surveyId) {
	const response = await axios.get(`${apiPath}surveys/${surveyId}/selects`);
	return response?.data;
}


async function loadTalkingPoints (surveyId, search, filters, offset, sortColumns) {
	const response = await axios.post(`${apiPath}surveys/${surveyId}/talking-points`, { search, offset, sortColumns, ...filters });
	return response?.data;
}


async function loadLocalEnglishTalkingPoints (surveyId, language, talkingPoints) {
	const response = await axios.post(
		`${apiPath}surveys/${surveyId}/market-based-talking-points`,
		{ language, talkingPoints }
	);
	return response?.data;
}


async function loadTranslations (surveyId, market, language, talkingPoints) {
	const response = await axios.post(
		`${apiPath}surveys/${surveyId}/translations`,
		{ market, language, talkingPoints }
	);
	return response?.data;
}


async function loadAnalytics (event, period) {
	const response = await axios.get(`${apiPath}analytics?event=${event}&start=${period[0]}&end=${period[1]}`);
	return response?.data;
}

async function loadTotalUniqueUsers () {
	const response = await axios.get(`${apiPath}analytics/users`);
	return response?.data;
}

async function loadUVMetrics (payload) {
	const response = await axios.post(`${apiPath}analytics/UVMetrics`, payload);
	return response?.data;
}

async function loadFiles (filesUrl, selectedLangs, selectedCountries, isExternal) {
	const response = (await axios.post(filesUrl, {
		languages: selectedLangs,
		countries: selectedCountries,
		isExternal
	}));
	return response?.data;
}

async function loadCollateralMethodologyFiles ({ surveyIds, types, countries, limit, includesGlobalFiles }) {
	const response = await axios.post(`${apiPath}methodology-collateral`, { surveyIds, types, countries, limit, includesGlobalFiles });
	return response?.data;
}

async function loadFiltersForFiles () {
	const response = await axios.get(`${apiPath}methodology-collateral/selects`);
	return response?.data;
}

async function loadCityscapesMaps () {
	const response = (await axios.get(`${apiPath}surveys/mapping-cityscapes`));
	return response?.data;
}

async function loadSavedSurveys () {
	const response = await axios.get(`${apiPath}favorites/surveys`);
	return response?.data;
}


async function saveSurvey (surveyId) {
	const response = await axios.post(`${apiPath}favorites/surveys`, { surveyId });
	return response?.data;
}


async function unsaveSurvey (surveyId) {
	const response = await axios.delete(`${apiPath}favorites/surveys/${surveyId}`);
	return response?.data;
}


async function loadSavedFilterSelections () {
	const response = await axios.get(`${apiPath}favorites/filter-selections`);
	return response?.data;
}


async function saveFilterSelection (surveyId, filters) {
	const response = await axios.post(`${apiPath}favorites/filter-selections`, { surveyId, filters });
	return response?.data;
}


async function deleteFilterSelection (selectionId) {
	const response = await axios.delete(`${apiPath}favorites/filter-selections/${selectionId}`);
	return response?.data;
}


async function submitUsageData (data) {
	const response = await axios.post(`${apiPath}usage`, { form: data });
	return response?.data;
}

async function loadWeeklyUsageData () {
	const response = await axios.get(`${apiPath}usage/weekly-reports/timeframes`);
	return response?.data;
}

async function loadWeeklyReport (weekNumber, year) {
	const response = await axios.get(`${apiPath}usage/weekly-reports/${weekNumber}/${year}`);
	return response?.data;
}

async function loadGaEventInformations (eventName, period) {
	const response = await axios.get(`${apiPath}analytics/event-report/${eventName}?&start=${period[0]}&end=${period[1]}`);
	return response?.data;
}

async function downloadAnalytics (period, types = [], params = []) {
	let url = `${apiPath}analytics/download?types=${types.toString()}&start=${period[0]}&end=${period[1]}`;

	for (const [key, value] of params) {

		url += `&${key}=${value}`;

	}

	const response = await axios({
		url,
		method: 'GET',
		responseType: 'blob'
	});
	return response?.data;
}

async function loadHPMetrics (country, period) {
	const response = await axios.get(`${apiPath}analytics/hp-metrics/${country}?start=${period[0]}&end=${period[1]}`);
	return response?.data;
}

async function loadGaTimeReport (type, period) {
	const response = await axios.get(`${apiPath}analytics/time-report/${type}?&start=${period[0]}&end=${period[1]}`);
	return response?.data;
}

async function loadHPMetricsCountries () {
	const response = await axios.get(`${apiPath}analytics/hp-metrics-countries`);
	return response?.data;
}

async function loadTalkingPointIcons () {
	const response = await axios.get(`${apiPath}talking-point/icons`);
	return response?.data;
}

async function loadCollateralDownloadsSelects () {
	const response = await axios.get(`${apiPath}analytics/collaterals/downloads/selects`);
	return response?.data;
}

async function storeCollateralAnalyticsInformations (informations) {
	const response = await axios.post(`${apiPath}analytics/collaterals/downloads`, {
		informations
	});
	return response?.data;
}

async function loadCollateralAnalyticsInformations (start, end, surveyID, marketIDs) {
	const marketIDsQuery = marketIDs && marketIDs.length ? `&marketIDs=${marketIDs}` : '';
	const response = await axios.get(`${apiPath}analytics/collaterals/downloads?start=${start}&end=${end}&surveyID=${surveyID}${marketIDsQuery}`);
	return response?.data;
}

export {
	loadMarketTrendings,
	ping, login, userInfo, updateUserLocation, updateUserAvatar,
	loadUserRecentDocuments, addRecentDocument,
	loadLastFavorites, loadUserGuide,
	loadSearchData,
	loadCountries, loadAvailableFilters, loadEquivalentFilters,
	loadAnalytics, loadTotalUniqueUsers, loadUVMetrics,
	loadSurveys, loadSurveyInfos,
	loadSurveySelects, loadTalkingPoints, loadLocalEnglishTalkingPoints, loadTranslations,
	loadSavedSurveys, saveSurvey, unsaveSurvey,
	loadSavedFilterSelections, saveFilterSelection, deleteFilterSelection,
	loadFiltersForFiles, loadFiles, loadCollateralMethodologyFiles,
	loadCityscapesMaps,
	submitUsageData, loadWeeklyUsageData, loadWeeklyReport,
	downloadAnalytics,
	loadGaEventInformations, loadHPMetrics, loadHPMetricsCountries, loadGaTimeReport,
	loadTalkingPointIcons,
	loadCollateralDownloadsSelects, storeCollateralAnalyticsInformations,
	loadCollateralAnalyticsInformations
};
