<template>
	<img :src="logoPath" alt="Meta logo" />
</template>

<script>
export default {
	props: {
		isWhite: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		logoPath: function () {
			return this.isWhite
				? require('@/assets/header/logo-meta-gpr-white.svg')
				: require('@/assets/header/logo-meta-gpr-grey.svg');
		}
	}
};
</script>

<style scoped>
img {
	height: 25px;
}
</style>
