import VueGoogleCharts from 'vue-google-charts';

export default {
	async install (Vue) {
		Vue.use(VueGoogleCharts);
		const mapsFile = await fetch('/config/maps.json');
		const mapsJson = await mapsFile.json();
		Vue.prototype.$gmApiKey = mapsJson.API_KEY;
	}
};
