const api = require('@/utils/api');
const { RECENT_DOCUMENT_TYPES, TRENDING_TYPES } = require('../constants/types');

const getDefaultState = () => ({
	markets: [],
	activeMarkets: [],
	selectedMarkets: [],
	initialMarkets: [],

	respondentTypes: [],
	activeRespondentTypes: [],
	selectedRespondentTypes: [],
	initialRespondentTypes: [],

	topics: [],
	activeTopics: [],
	selectedTopics: [],
	initialTopics: [],

	loadingSurveys: false,
	totalSurveys: null,
	filteredSurveys: [],
	userRecentSurveys: [],
	trendingSurveys: []
});

const state = getDefaultState();

const mutations = {
	resetState (state) {
		Object.assign(state, getDefaultState());
	},

	setMarkets (state, data) {
		state.markets = data;
	},
	setActiveMarkets (state, data) {
		state.activeMarkets = data;
	},
	setSelectedMarkets (state, data) {
		state.selectedMarkets = data;
	},

	setRespondentTypes (state, data) {
		state.respondentTypes = data;
	},
	setActiveRespondentTypes (state, data) {
		state.activeRespondentTypes = data;
	},
	setSelectedRespondentTypes (state, data) {
		state.selectedRespondentTypes = data;
	},

	setTopics (state, data) {
		state.topics = data;
	},
	setActiveTopics (state, data) {
		state.activeTopics = data;
	},
	setSelectedTopics (state, data) {
		state.selectedTopics = data;
	},

	setLoadingSurveys (state, data) {
		state.loadingSurveys = data;
	},
	setTotalSurveys (state, data) {
		state.totalSurveys = data;
	},
	setFilteredSurveys (state, data) {
		state.filteredSurveys = data;
	},
	setUserRecentSurveys (state, data) {
		state.userRecentSurveys = data;
	},
	setTrendingSurveys (state, data) {
		state.trendingSurveys = data;
	},

	setInitialFilters (state, filters) {
		const { markets, respondents, topics } = filters;
		state.initialMarkets = markets || [];
		state.initialRespondentTypes = respondents || [];
		state.initialTopics = topics || [];
	}
};

const actions = {
	resetSurveysState ({ commit }) {
		commit('resetState');
	},

	async loadAvailableFilters ({ state, commit }) {
		const availableFilters = await api.loadAvailableFilters({
			markets: state.selectedMarkets,
			respondentTypes: state.selectedRespondentTypes,
			topics: state.selectedTopics
		});
		const { markets, respondentTypes, topics } = availableFilters;
		if (markets) commit('setActiveMarkets', markets);
		if (respondentTypes) commit('setActiveRespondentTypes', respondentTypes);
		if (topics) commit('setActiveTopics', topics);
	},
	async loadSurveys ({ state, commit }) {
		commit('setLoadingSurveys', true);
		try {
			const data = await api.loadSurveys({
				markets: state.selectedMarkets,
				respondentTypes: state.selectedRespondentTypes,
				topics: state.selectedTopics
			});

			if (data) {
				const { total, surveys } = data;

				commit('setTotalSurveys', total);
				commit('setFilteredSurveys', surveys);
			}
		} catch (e) {
			commit('setLoadingSurveys', false);
		} finally {
			commit('setLoadingSurveys', false);
		}
	},
	async loadUserRecentSurveys ({ commit }) {
		commit('setUserRecentSurveys', await api.loadUserRecentDocuments([RECENT_DOCUMENT_TYPES.SURVEY]));
	},
	async loadTrendingSurveys ({ commit }) {
		const surveys = await api.loadMarketTrendings([TRENDING_TYPES.SURVEY]) || [];
		commit('setTrendingSurveys', surveys);
	}
};

export default {
	namespaced: true,
	state,
	actions,
	mutations
};
