<template>

	<v-menu
		offset-y
		:attach="`#js-header-links-${randomToken}`"
		:nudge-width="100"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				:ripple="false"
				icon
				elevation="0"
				:color="isWhite ? 'white' : 'metaGray'"
				v-bind="attrs"
				v-on="on"
			>
				<span class="material-icons-outlined">
					menu
				</span>
			</v-btn>
		</template>

		<ul class="menu-list">
			<li v-for="(link, idx) in links" :key="idx">
				<router-link :to="link.to">
					{{ link.label }}
				</router-link>
			</li>
		</ul>
	</v-menu>

</template>


<script>

export default {

	name: 'HeaderLinksDropdown',

	props: {
		links: {
			type: Array,
			default: () => []
		},
		isWhite: {
			type: Boolean,
			default: false
		},
		randomToken: {
			type: String,
			required: true
		}
	}

};

</script>

<style lang="scss" scoped>
  @import "@/scss/_colors.scss";
  .avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 32px;
    height: 32px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .menu-list {
    background-color: $gray-100;
    color: $gray-100;
    white-space: nowrap;

    li {
      z-index: 999;
      height: 30px;
      padding: 4px 8px;
      font-size: 15px;
      border-bottom: 1px solid $gray-100;
      cursor: pointer;

      &:first-child {
        border-bottom: 1px solid $light-gray-02;
      }

      a {
        color: $meta-gray !important;
      }

    }
  }
</style>
