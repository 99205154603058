<template>
	<v-app>
		<router-view v-if="$route.name === 'Login' || $route.name === 'Generate'"></router-view>
		<div style="height: 100%;" v-else>

			<div id="main" class="d-flex flex-column h-100">

				<div class="app__header__container" v-if="$route.name !== 'Home'">
					<Header />
				</div>

				<div id="content" >
					<router-view></router-view>
				</div>

				<Footer />

			</div>
		</div>

	</v-app>
</template>


<script>

import Header from '@/components/layouts/Header';
import Footer from '@/components/layouts/Footer';

export default {
	name: 'App',

	components: {
		Header,
		Footer
	}

};

</script>

<style lang="scss">

@import "@/scss/global.scss";

  .app__header__container {
    background: #fff;
    height: $header-height;
    position: fixed;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid $light-gray-03;
  }

</style>
