import moment from 'moment';
import router from '@/router';
import { STEPS } from '@/store/general';

const axios = require('axios');

const api = require('@/utils/api');
const { copyImageToClipboard } = require('@/utils/clipboard');

const getDefaultState = () => ({
	isRestoringReportPending: false,
	isEditMode: true,
	hasIcons: true,
	selectedSlide: 1,
	isInternal: false,
	nbPerSlide: 6,
	selectedMapOption: 'globe',
	methodologyDocs: [],
	exportType: 'PDF',
	methodologyDownloadPopup: false,
	usageFormPopup: false,
	methodologyDownloadToDownload: false,
	doneDownloading: false,
	showProcessing: false,
	showError: false
});

const state = getDefaultState();

const mutations = {
	resetState (state) {
		Object.assign(state, getDefaultState());
	},
	setMapOption (state, option) {
		state.selectedMapOption = option;
	},
	setMethodologyDocs (state, data) {
		state.methodologyDocs = data;
	},
	setExportType (state, data) {
		state.exportType = data;
	},
	setMethodologyDownloadPopup (state, data) {
		state.methodologyDownloadPopup = data;
	},
	setUsageFormPopup (state, data) {
		state.usageFormPopup = data;
	},
	setMethodologyDownloadToDownload (state, data) {
		state.methodologyDownloadToDownload = data;
	},
	setDoneDownloading (state, data) {
		state.doneDownloading = data;
	},
	setIsRestoringReportPending (state, data) {
		state.isRestoringReportPending = data;
	},
	setShowProcessing (state, data) {
		state.showProcessing = data;
	},
	setShowError (state, data) {
		state.showError = data;
	},
	setHasIcons (state, data) {
		state.hasIcons = data;
	},
	setIsInternal (state, data) {
		state.isInternal = data;
	},

	toggleIsEditMode (state) {
		state.isEditMode = !state.isEditMode;
	},
	toggleIcons (state, option) {
		state.hasIcons = option === 'Display icons';
	},
	toggleAudience (state, option) {
		state.hasIcons = true;
		state.isInternal = option === 'Internal';
		state.nbPerSlide = state.isInternal ? 9 : 6;
	},
	setSelectedSlide (state, data) {
		state.selectedSlide = data;
	}
};

const actions = {
	resetReportState ({ commit }) {
		commit('resetState');
	},

	async restoreBuildExperienceSession ({ state, dispatch, commit, rootState, rootGetters }, report) {
		try {

			commit('setIsRestoringReportPending', true);

			commit('talkingPoints/setSelectedSurveyId', report.surveyId, { root: true });
			commit('talkingPoints/setExperiences', report.experiences, { root: true });
			commit('talkingPoints/setExperienceToken', report.experienceToken, { root: true });

			commit('setHasIcons', report.hasIcons);
			commit('setIsInternal', report.isInternal);
			commit('setMapOption', report.mapOption);
			commit('talkingPoints/setMarketsForMaps', rootGetters['talkingPoints/selectedExperiencesMarkets'].map(e => e.label), { root: true });

			commit('general/changeStep', STEPS.REPORT, { root: true });

			router.push({ path: '/build-experience?surveyId=' + report.surveyId });

			await dispatch('talkingPoints/loadSurveyInfos', null, { root: true });

			dispatch('talkingPoints/updateLanguages', report.language, { root: true });

			await dispatch('talkingPoints/loadIcons', null, { root: true });
			await dispatch('talkingPoints/updateTranslations', null, { root: true });

		} catch (e) {

			console.error(e);

		} finally {

			commit('setIsRestoringReportPending', false);

		}

	},

	usageFormDone ({ dispatch, commit }) {
		commit('setUsageFormPopup', false);

		dispatch('download');
		dispatch('downloadMethodologyFiles');

		commit('setDoneDownloading', true);

		dispatch('talkingPoints/resetFilters', null, { root: true });
		commit('talkingPoints/setSearch', '', { root: true });
		dispatch('talkingPoints/resetTalkingPoints', null, { root: true });
	},
	methodologyDownloadDone ({ commit }, toDownload) {
		commit('setMethodologyDownloadToDownload', !!toDownload);
		commit('setMethodologyDownloadPopup', false);
		commit('setUsageFormPopup', true);
	},
	closeDialog ({ commit }) {
		commit('setMethodologyDownloadToDownload', false);
		commit('setMethodologyDownloadPopup', false);
		commit('setUsageFormPopup', false);
	},

	async download ({ state, dispatch, commit, rootState }) {
		const { surveyDate, surveyTitle } = rootState.talkingPoints;

		try {

			const response = await dispatch('_generate');

			const file = new Blob([response.data], { type: response.headers['content-type'] });
			const fileURL = URL.createObjectURL(file);
			const fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', [surveyTitle, surveyDate, moment().format('MM-DD-YYYY')].join('-'));
			document.body.appendChild(fileLink);

			fileLink.click();

		} catch (e) {

			commit('setShowError', true);

		} finally {

			commit('setShowProcessing', false);

		}

	},

	_generate ({ state, rootState, commit, rootGetters }, idToClip = undefined) {
		const { experiences, translatedExperiences, texts, selectedLanguage, experienceToken, selectedSurveyId } = rootState.talkingPoints;

		if (!experiences.length) return;

		commit('setShowProcessing', true);

		return axios
			.post(
				'/api/v1/generate', {
					surveyId: selectedSurveyId,
					experienceToken,
					language: selectedLanguage,
					mapOption: state.selectedMapOption,
					experiences: translatedExperiences,
					hasIcons: state.hasIcons,
					type: state.exportType,
					texts: texts,
					isInternal: state.isInternal,
					nbPerSlide: state.nbPerSlide,
					rtl: selectedLanguage.writing === 'RTL',
					mapClass: rootGetters['talkingPoints/mapClass'],
					isLandscape: false, // TODO
					idToClip
				},
				{
					method: 'POST',
					responseType: 'blob'
				}
			);
	},

	async exportSingleTalkingPoint ({ state, dispatch, commit, rootState }) {
		const { talkingPointExport } = rootState.talkingPoints;

		commit('talkingPoints/changeTalkingPointExportShowDialog', false, { root: true });

		try {

			const response = await dispatch('_generate', talkingPointExport.id);

			const file = new Blob([response.data], { type: response.headers['content-type'] });
			copyImageToClipboard(file);

		} catch (e) {

			console.error('An error occurred', { e });

		} finally {

			commit('setShowProcessing', false);

		}
	},

	async downloadMethodologyFiles ({ state, commit }) {
		if (!state.methodologyDownloadToDownload) return;

		for (const file of state.methodologyDocs) {
			const URL = `/api/v1/static-file/download?fileKey=${file.key}`;

			try {

				const response = await axios.get(URL, { responseType: 'blob' });

				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');

				link.href = url;
				link.setAttribute('download', `${file.name}.${file.extension.toLowerCase()}`);
				document.body.appendChild(link);
				link.click();

			} catch (e) {
				console.error('An error occurred');
			}

		}

		commit('setMethodologyDownloadToDownload', false);
	},

	async onSelectExport ({ commit, rootState, rootGetters }, exportType) {
		const { selectedSurveyId } = rootState.talkingPoints;

		const selectedExperiencesMarketObjects = rootGetters['talkingPoints/selectedExperiencesMarketObjects'];

		commit('setExportType', exportType);

		const methodologyDocs = await api.loadFiles(
			`/api/v1/surveys/${selectedSurveyId}/methodology`,
			null,
			selectedExperiencesMarketObjects
		);

		commit('setMethodologyDocs', methodologyDocs);

		if (methodologyDocs.length) commit('setMethodologyDownloadPopup', true);
		else commit('setUsageFormPopup', true);

	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
