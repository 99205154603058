function getCountryIds (markets) {
	return markets
		.map((region) => region.items || []).flat()
		.map((country) => country.id);
}

function getStateIds (markets) {
	return markets
		.map((region) => region.items || []).flat()
		.map((country) => country.items || []).flat()
		.map((state) => state.id);
}

function isState (market) {
	return market.countryName && market.countryName !== market.label;
}

function sortInactive (inactiveA, inactiveB) {
	if (inactiveA && !inactiveB) return 1;
	if (!inactiveA && inactiveB) return -1;
	return 0;
}

function formatSurveyWithWave (survey) {
	const waveRegex = /-\sWave\s(\d+)$/;

	const [waveSubstring, wave] = survey.title.match(waveRegex) || [];

	const title = survey.title.replace(new RegExp(waveSubstring), '').trim();

	return {
		...survey,
		title,
		wave
	};
}

module.exports = {
	formatSurveyWithWave,
	getCountryIds,
	getStateIds,
	isState,
	sortInactive
};
