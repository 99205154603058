const api = require('@/utils/api');

const state = () => ({
	countries: []
});

const mutations = {
	setCountries (state, data) {
		state.countries = data;
	}
};

const actions = {
	async loadCountries ({ commit }) {
		commit('setCountries', await api.loadCountries());
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions
};
