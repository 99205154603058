import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				green01: '#EBF2E6',
				green02: '#C3E1C8',
				green06: '#007E59',
				green08: '#005A42',
				lightRed: '#FFEBEB',
				strongRed: '#B92328',
				metaBlue: '#0064E0',
				blue600: '#0082FB',
				metaGray: '#1C2B33',
				gray600: '#67788A',
				gray300: '#CBD2D9',
				gray100: '#F1F4F7'
			}
		}
	}
});
