<template>

	<footer v-if="isFooterShown">
		<div class="d-flex align-center">
			<metaLockup />
			<v-spacer></v-spacer>
			<ul class="links d-flex">
				<li v-for="(link, index) in links" :key="index">
					<a class="hover__underline" target="_blank" :href="link.url">{{ link.label }}</a>
				</li>
			</ul>
		</div>
	</footer>

</template>

<script>

import metaLockup from '@/components/logos/meta-lockup';
const { mapState } = require('vuex');

export default {

	components: {
		metaLockup
	},

	computed: {
		...mapState({
			isFooterShown: (state) => state.general.isFooterShown
		})
	},

	data () {
		return {
			links: [
				{ label: 'Terms', url: 'https://www.facebook.com/legal/terms' },
				{ label: 'Cookies', url: 'https://www.facebook.com/policies/cookies/' },
				{ label: 'Data Policy', url: 'https://www.facebook.com/policy.php' },
				{ label: 'Facebook', url: 'https://www.facebook.com/' },
				{ label: 'Privacy Policy', url: 'https://www.facebook.com/about/basics' }
			]
		};
	}

};

</script>

<style lang="scss" scoped>

@import "@/scss/_colors.scss";

footer {
  margin-top: 64px;
  height: 300px;
  background: $gray-100;
  border-top: 1px solid $light-gray-03;
  padding: 0 32px;

  > div {
    margin-top: 64px;
  }

  svg {
    fill: $gray-800;
    height: 10px;
  }
  ul.links {

    li {
      margin-left: 10px;

      a {
        color: #000 !important;
        font-weight: 500;
        font-family: "Optimistic Display", 'Noto Sans Khmer', sans-serif;
        font-size: 14px;
        position: relative;
      }
    }
  }
}

</style>
