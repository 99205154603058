import router from '@/router';

const api = require('@/utils/api');
const { isState } = require('@/utils/data');
const { changeOrder } = require('@/utils/swap');
const { randomToken } = require('@/utils/helpers');

const getDefaultState = () => ({
	isTalkingPointsCartOpen: false,
	experienceToken: '',

	markets: [],
	respondentTypes: [],
	topics: [],

	initialMarkets: [],
	initialRespondentTypes: [],
	initialTopics: [],

	selectedMarkets: [],
	selectedRespondentTypes: [],
	selectedTopics: [],

	selectionSaved: false,

	search: '',
	talkingPoints: [],
	totalTalkingPoints: 0,
	loadingTalkingPoints: false,
	previousOffset: 0,
	sortColumns: {},

	selectedSurveyId: null,
	surveySaved: false,
	maps: [],
	marketsForMaps: [],
	selectedLanguage: {},
	texts: {},
	surveyTitle: '',
	surveyDate: '',
	previewURL: null,
	defaultTexts: {},
	icons: [],
	defaultIcon: '',
	languages: [],
	localLanguages: [],
	globalLanguages: [],
	experiences: [],
	translatedExperiences: [],
	talkingPointExport: {
		showDialog: false,
		id: ''
	}
});

const state = getDefaultState();

const mutations = {
	resetState (state, excludes = []) {
		const defaultState = getDefaultState();
		excludes.forEach((key) => delete defaultState[key]);
		Object.assign(state, defaultState);
	},

	setExperienceToken (state, value) {
		state.experienceToken = value;
	},
	changeTalkingPointExportShowDialog (state, value) {
		state.talkingPointExport.showDialog = value;
	},
	initiateSingleTalkingPoint (state, id) {
		state.talkingPointExport.id = id;
		state.talkingPointExport.showDialog = true;
	},
	setSelectedSurveyId (state, surveyId) {
		state.selectedSurveyId = surveyId;
	},
	setMarketsForMaps (state, data) {
		state.marketsForMaps = data;
	},
	setSelectedLanguage (state, language) {
		state.selectedLanguage = language;
	},
	setLanguages (state, languages) {
		state.languages = languages;
	},
	setTexts (state, texts) {
		state.texts = texts;
	},
	setPreviewURL (state, previewURL) {
		state.previewURL = previewURL;
	},
	setDefaultTexts (state, defaultTexts) {
		state.defaultTexts = defaultTexts;
	},
	setLocalLanguages (state, localLanguages) {
		state.localLanguages = localLanguages;
	},
	setGlobalLanguages (state, globalLanguages) {
		state.globalLanguages = globalLanguages;
	},
	setTranslatedExperiences (state, data) {
		state.translatedExperiences = data;
	},
	setExperiences (state, experiences) {
		state.experiences = [...state.experiences, ...experiences];
	},
	removeTalkingPoint (state, talkingPointId) {
		state.experiences = state.experiences.filter(experience => experience.id !== talkingPointId);
		state.translatedExperiences = state.translatedExperiences.filter(experience => experience.id !== talkingPointId);
	},
	swapExperiences (state, change) {
		state.experiences = changeOrder(state.experiences, change);
		state.translatedExperiences = changeOrder(state.translatedExperiences, change);
	},
	setIsTalkingPointsCartOpen (state, value) {
		state.isTalkingPointsCartOpen = value;
	},
	setSurveyTitle (state, surveyTitle) {
		state.surveyTitle = surveyTitle;
	},
	setSurveySaved (state, data) {
		state.surveySaved = data;
	},
	setSurveyDate (state, surveyDate) {
		state.surveyDate = surveyDate;
	},
	setIcons (state, data) {
		state.icons = data;
	},
	setDefaultIcon (state, data) {
		state.defaultIcon = data;
	},
	setMaps (state, data) {
		state.maps = data;
	},

	setMarkets (state, markets) {
		state.markets = markets;
	},
	setRespondentTypes (state, respondentTypes) {
		state.respondentTypes = respondentTypes;
	},
	setTopics (state, topics) {
		state.topics = topics;
	},
	setInitialFilters (state, filters) {
		const { markets, respondents, topics } = filters;
		state.initialMarkets = markets || [];
		state.initialRespondentTypes = respondents || [];
		state.initialTopics = topics || [];
	},
	setSelectedMarkets (state, data) {
		state.selectedMarkets = data;
	},
	setSelectedRespondentTypes (state, data) {
		state.selectedRespondentTypes = data;
	},
	setSelectedTopics (state, data) {
		state.selectedTopics = data;
	},
	setSelectionSaved (state, data) {
		state.selectionSaved = data;
	},

	setSearch (state, data) {
		state.search = data;
	},
	setTalkingPoints (state, data) {
		state.talkingPoints = data;
	},
	setTotalTalkingPoints (state, data) {
		state.totalTalkingPoints = data;
	},
	setLoadingTalkingPoints (state, data) {
		state.loadingTalkingPoints = data;
	},
	setPreviousOffset (state, data) {
		state.previousOffset = data;
	},
	setSortColumns (state, data) {
		state.sortColumns = data;
	}
};

const getters = {
	computeCommonRegion (state) {
		if (state.marketsForMaps.length === 1) {
			return state.marketsForMaps[0];
		}
		const commonRegion = state.maps.find(e => e.market === state.marketsForMaps[0]);

		if (!commonRegion) return 'global';

		for (const market of state.marketsForMaps) {
			const region = state.maps.find(e => e.market === market)?.region || market;
			if (region !== commonRegion.region) {
				return 'global';
			}
		}

		return commonRegion.region;
	},
	optionsForMaps (state, getters, rootState, rootGetters) {
		try {

			const commonRegion = getters.computeCommonRegion;

			if (commonRegion === 'global') {
				return ['map', 'none of the above'];
			}

			const options = [];
			const icons = state.maps.filter(e => e.market.toLowerCase() === commonRegion.toLowerCase());

			for (const icon of icons) {
				const type = icon?.image?.substring(0, icon.image.indexOf('_'));
				options.push(type);
			}

			if (options.length === 0) options.push('globe');

			options.push('none of the above');

			return options;

		} catch (e) {
			return ['none of the above'];
		}
	},
	mapClass (state, getters, rootState, rootGetters) {
		const { selectedMapOption } = rootState.report;

		if (!state.marketsForMaps?.length) { return 'map_Global-Map'; }

		const commonRegion = getters.computeCommonRegion;

		if (selectedMapOption === 'none of the above') {
			return '';
		}
		if (commonRegion === 'global') {
			return 'map_Global-Map';
		}

		const label = commonRegion.toLowerCase();

		const markets = state.maps.filter(e => e.market.toLowerCase() === label);

		if (markets.length > 1) {

			const option = (selectedMapOption === '' || selectedMapOption === 'globe') ? 'globe' : selectedMapOption;

			let icon = markets.filter(e => e.image.startsWith(option));

			if (icon.length === 0) { icon = markets.filter(e => e.image.startsWith('globe')); }
			if (icon.length === 0) { icon = markets.filter(e => e.image.startsWith('map')); }
			if (icon.length === 0) { icon = markets.filter(e => e.image.startsWith('cityscape')); }

			return icon[0].image;
		}

		return markets[0]?.image || 'map_Global-Map';
	},
	selectedExperiencesMarketObjects (state) {
		return state.experiences
			.filter(exp => exp.market?.countryCode !== '000')
			.map(pt => ({
				id: pt.market.id,
				countryName: pt.market.countryName,
				countryCode: pt.market.countryCode,
				state: pt.market.levelId === 'D' ? pt.market.label : null
			}))
			.reduce((items, cur) => {
				if (!items.find(it => it.id === cur.id)) {
					items.push(cur);
				}
				return items;
			}, []);
	},
	selectedExperiencesMarkets (state) {
		return state.experiences.map(pt => pt.market)
			.reduce((items, cur) => {
				if (!items.find(a => a.id === cur.id)) {
					items.push(cur);
				}
				return items;
			}, []);
	},
	marketIds (state) {
		return [...new Set(state.experiences.map((item) => item.market.id))];
	},
	defaultLanguage (state) {
		return state.globalLanguages[0];
	},
	getActiveLanguages: (state, getters) => (marketId) => {
		const languages = state.localLanguages.filter((item) => item.market.id === marketId);

		if (!languages.length) return [getters.defaultLanguage];

		const englishIndex = languages.findIndex((language) => language.id === 'en');
		if (englishIndex === -1) return [getters.defaultLanguage, ...languages];

		return languages;
	}
};

const actions = {
	resetTalkingPointsState ({ commit }, excludes) {
		commit('resetState', excludes);
	},
	async loadSelects ({ state, commit }, mode) {
		const data = await api.loadSurveySelects(state.selectedSurveyId);

		if (!data) return;

		const { businesses, consumers, markets, topics } = data;

		commit('setRespondentTypes', [
			{ label: 'Businesses', items: businesses },
			{ label: 'Consumers', items: consumers }
		].filter((category) => category.items.length)
		);

		commit('setTopics', topics);

		if (mode.isStateMode) {
			commit('setMarkets', markets.filter((market) =>
				isState(market) &&
        market.countryCode === mode.countryCode
			));
		} else {
			commit('setMarkets', markets.filter((market) =>
				!isState(market)
			));
		}
	},
	async onToggleSaveSelection ({ state, commit }) {
		if (state.selectionSaved) return;

		const filters = {
			markets: state.selectedMarkets,
			respondents: state.selectedRespondentTypes,
			topics: state.selectedTopics
		};
		const result = await api.saveFilterSelection(state.selectedSurveyId, filters);
		if (result) {
			commit('setSelectionSaved', true);
		}
	},
	async updateTalkingPoints ({ state, commit }, initialPoints) {
		const initialTalkingPoints = initialPoints || state.talkingPoints;

		const filters = {
			respondentTypes: state.selectedRespondentTypes,
			markets: state.selectedMarkets.length
				? state.selectedMarkets
				: state.markets,
			topics: state.selectedTopics
		};

		commit('setLoadingTalkingPoints', true);
		const data = await api.loadTalkingPoints(state.selectedSurveyId, state.search, filters, state.previousOffset, state.sortColumns);
		commit('setLoadingTalkingPoints', false);

		if (data) {
			commit('setTotalTalkingPoints', data.total);
			const talkingPointIds = initialTalkingPoints.map((item) => item.id);
			const newTalkingPoints = data.talkingPoints
				.filter((item) => !talkingPointIds.includes(item.id))
				.map((item) => ({ ...item, icon: state.defaultIcon }));
			commit('setTalkingPoints', helperMethods.highlightMatchedText([...initialTalkingPoints, ...newTalkingPoints], state.search));
		}
	},
	resetFilters ({ commit }) {
		commit('setSelectedMarkets', []);
		commit('setSelectedRespondentTypes', []);
		commit('setSelectedTopics', []);
		commit('setSelectionSaved', false);
	},
	resetTalkingPoints ({ commit, dispatch }) {
		commit('setPreviousOffset', 0);
		commit('setTotalTalkingPoints', 0);
		dispatch('updateTalkingPoints', []);
	},
	selectSurvey ({ state, commit, dispatch, rootState }, selectedSurveyId) {
		dispatch('report/resetReportState', null, { root: true });
		dispatch('resetTalkingPointsState', ['initialMarkets', 'initialRespondentTypes', 'initialTopics']);

		commit('setExperienceToken', randomToken());

		commit('setSelectedSurveyId', selectedSurveyId);

		const { surveyId } = router.history.current.query;

		if (selectedSurveyId !== surveyId) {
			router.push({ path: '/build-experience?surveyId=' + selectedSurveyId });
		}

	},
	async toggleSurveySaved ({ state, commit }) {
		commit('setSurveySaved', !state.surveySaved);

		if (state.surveySaved) {
			await api.saveSurvey(state.selectedSurveyId);
		} else {
			await api.unsaveSurvey(state.selectedSurveyId);
		}
	},

	async loadCityscapesMaps ({ dispatch, commit }) {
		commit('setMaps', await api.loadCityscapesMaps());
	},
	async loadSurveyInfos ({ state, commit }) {

		try {

			const result = await api.loadSurveyInfos(state.selectedSurveyId);

			const { title, header, subHeader, description, methodology, languages, saved } = result;

			commit('setSurveyTitle', title);
			commit('setDefaultTexts', { header, subHeader, description, methodology });
			commit('setLocalLanguages', languages.local);
			commit('setGlobalLanguages', languages.global);
			commit('setSurveySaved', saved);

		} catch (e) {

			commit('resetState');

		}

	},
	async loadTranslations  ({ state, commit }) {
		const language = state.selectedLanguage;

		const translations = await api.loadTranslations(
			state.selectedSurveyId,
			language.market,
			{ id: language.id, name: (language.name === 'US English') ? 'English' : language.name },
			state.experiences.map((item) => ({ talkingPointId: item.talkingPointId, market: item.market }))
		);

		if (translations) {

			const { header, subHeader, description, methodology, talkingPoints } = translations;

			commit('setTexts', { header, subHeader, description, methodology });

			const oldTranslatedExperiences = state.translatedExperiences.map(t => Object.assign({}, t));

			const experiences = state.experiences.map((e, index) => ({
				...e,
				title: talkingPoints[index],
				surchargedIcon: e.surchargedIcon || oldTranslatedExperiences.find(ote => ote.id === e.id)?.surchargedIcon
			}));

			commit('setTranslatedExperiences', experiences);

		}
	},
	async updateTranslations ({ state, getters, commit, dispatch }) {
		const selectedExperiencesMarkets = getters.selectedExperiencesMarkets;

		if (state.selectedLanguage === state.globalLanguages[0]) {

			commit('setTexts', state.defaultTexts);

			if (selectedExperiencesMarkets.length > 1) {

				await dispatch('loadTranslations');

			} else {

				commit('setTranslatedExperiences', state.experiences);

			}

		} else {

			await dispatch('loadTranslations');

		}
	},
	async handleToggleTalkingPoint ({ state, getters, commit, dispatch }, experiences) {
		if (!state.experiences.length) {

			commit('setIsTalkingPointsCartOpen', true);

			setTimeout(() => {
				commit('setIsTalkingPointsCartOpen', false);
			}, 2000);

		}

		const marketIds = getters.marketIds;
		commit('setExperiences', experiences);
		await dispatch('updatePreviewStates', { experiences, previousMarkets: marketIds });
	},
	async loadIcons ({ getters, commit, dispatch }) {
		const { icons, defaultIcon } = await api.loadTalkingPointIcons();

		commit('setIcons', icons);
		commit('setDefaultIcon', defaultIcon);
	},
	updateLanguages ({ getters, commit }, language) {
		const marketIds = getters.marketIds;
		const languages = marketIds.length > 1 ? state.globalLanguages : getters.getActiveLanguages(marketIds[0]);

		commit('setLanguages', languages);
		commit('setSelectedLanguage', language);
	},
	async updatePreviewStates ({ getters, commit, dispatch }, { experiences, previousMarkets }) {
		commit('setMarketsForMaps', getters.selectedExperiencesMarkets.map(e => e.label));

		const currentMarkets = getters.marketIds;

		if (!currentMarkets.length) {
			commit('setLanguages', []);
			commit('setSelectedLanguage', {});
			commit('setTexts', {});

			commit('setTranslatedExperiences', []);
		} else if (currentMarkets.length === 1) {

			if (previousMarkets.length !== 1) {
				commit('setLanguages', getters.getActiveLanguages(currentMarkets[0]));
				commit('setSelectedLanguage', state.languages[0]);
			}

			await dispatch('updateTranslations');

		} else {
			if (previousMarkets.length === 1) {
				commit('setLanguages', state.globalLanguages);
				commit('setSelectedLanguage', getters.defaultLanguage);
				commit('setTexts', state.defaultTexts);
			}
			dispatch('loadLocalEnglishTalkingPoints');
		}
	},
	async loadLocalEnglishTalkingPoints ({ state, commit }) {
		const talkingPointsWithLocalEnglish = state.experiences
			.map((point, index) => ({
				talkingPointId: point.talkingPointId,
				market: point.market,
				index
			}))
			.filter((point) =>
				state.localLanguages.find((item) => item.id === 'en' && item.market.id === point.market.id)
			);

		if (!talkingPointsWithLocalEnglish.length) {
			commit('setTranslatedExperiences', state.experiences);
			return;
		}

		const data = await api.loadLocalEnglishTalkingPoints(
			state.selectedSurveyId,
			{ id: 'en' },
			talkingPointsWithLocalEnglish
		);

		if (!data) {
			commit('setTranslatedExperiences', state.experiences);
			return;
		}

		commit('setTranslatedExperiences', state.experiences.map((point, index) => ({
			...point,
			title: data.talkingPoints.find((item) => item.index === index)?.title || point.title
		})));
	}
};

const helperMethods = {
	highlightMatchedText (talkingPoints, search) {
		if (!search) return talkingPoints;

		const pattern = new RegExp(search, 'gi');
		return talkingPoints.map((point) => {
			const allMatches = point.title.matchAll(pattern);
			let highlightedTitle = '';
			let cursor = 0;
			for (const match of allMatches) {
				highlightedTitle = highlightedTitle + point.title.slice(cursor, match.index) + `<b>${match}</b>`;
				cursor = match.index + match[0].length;
			}
			return { ...point, highlightedTitle: highlightedTitle + point.title.slice(cursor) };
		});
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
