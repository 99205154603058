const api = require('@/utils/api');

const state = () => ({
	isExporting: false,
	globalDateRange: [],
	collateralDownloadSurveyId: null
});

const mutations = {
	setIsExporting (state, data) {
		state.isExporting = data;
	},
	setGlobalDateRange (state, data) {
		state.globalDateRange = data;
	},
	setCollateralDownloadSurveyId (state, data) {
		state.collateralDownloadSurveyId = data;
	}
};

const actions = {
	async downloadAnalytics ({ state, commit }, { types = [], period = [], ...rest }) {
		const params = !types.length
			? { surveyID: state.collateralDownloadSurveyId }
			: rest;

		const paramsAsKeyValue = Object.entries(params);

		try {

			commit('setIsExporting', true);

			const doc = await api.downloadAnalytics(period, types, paramsAsKeyValue);

			const docUrl = document.createElement('a');

			docUrl.href = window.URL.createObjectURL(new Blob([doc]));
			docUrl.setAttribute('download', 'analytics.pdf');

			document.body.appendChild(docUrl);

			docUrl.click();

		} catch (e) {

			console.error(e);

		} finally {

			commit('setIsExporting', false);

		}

	}
};

export default {
	namespaced: true,
	state,
	actions,
	mutations
};
