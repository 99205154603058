import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import HighchartsVue from 'highcharts-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import GoogleAnalytics from './plugins/google-analytics';
import GoogleCharts from './plugins/google-charts';
import Sentry from './plugins/sentry';

Vue.use(GoogleAnalytics);
Vue.use(GoogleCharts);
Vue.use(Sentry);

Vue.use(HighchartsVue);

// Setup Axios
// In case of 401 (Unauthorized), redirect to the login page
Vue.use(VueAxios, axios);

axios.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response;
}, function (error) {
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error
	const href = window.location.href.toLocaleLowerCase();
	if (error.response.status === 401 && !href.includes('login')) {
		const protocol = href.split('/')[0];
		const host = href.split('/')[2];
		window.location.href = `${protocol}//${host}/login`;
	} else {
		return Promise.reject(error);
	}
});

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app');
