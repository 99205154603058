<template>
	<header class="header" :class="{ '_white': isWhite}">
		<div class="d-flex align-center justify-space-between">

			<div>
				<router-link to="/" class="logo">
					<metaPolicy :isWhite="isWhite" />
				</router-link>
			</div>

			<div :id="`js-header-links-${randomToken}`" class="d-flex align-center">
				<div class="header__links-dropdown-container">
					<HeaderLinksDropdown
						:links="links"
						:isWhite="isWhite"
						:randomToken="randomToken"
						class="header__links-dropdown"
					/>
				</div>

				<div class="header__links-container">
					<ul class="header__links">
						<li v-for="(link, idx) in links" :key="idx" class="hover__underline">
							<router-link :to="link.to">{{ link.label }}</router-link>
						</li>
					</ul>
				</div>

				<HeaderUserDropdown :isWhite="isWhite" :randomToken="randomToken" />
			</div>

		</div>
	</header>
</template>


<script>

import metaPolicy from '@/components/logos/meta-policy';
import HeaderUserDropdown from './HeaderUserDropdown.vue';
import HeaderLinksDropdown from './HeaderLinksDropdown.vue';

const { randomToken } = require('@/utils/helpers');

const { mapState, mapActions } = require('vuex');

export default {

	name: 'Header',

	props: {
		isWhite: {
			type: Boolean,
			default: false
		}
	},

	components: {
		metaPolicy,
		HeaderUserDropdown,
		HeaderLinksDropdown
	},

	mounted: function () {
		this.loadUserInfos();
	},

	data () {
		return {
			links: [
				{ to: '/', label: 'Home' },
				{ to: '/build-experience', label: 'Build Experience' },
				{ to: '/content-library', label: 'Content Library' },
				{ to: '/user-guide', label: 'User Guide' },
				{ to: '/frequently-asked-questions', label: 'FAQ' }
			]
		};
	},


	methods: {
		...mapActions('general', [
			'loadUserInfos'
		]),

		async signOut () {
			window.location.href = '/security/signout';
		}
	},

	computed: {
		...mapState({
			user: (state) => state.general.user
		}),
		randomToken: function () {
			return randomToken();
		}
	}

};

</script>

<style lang="scss" scoped>
  @import "@/scss/_colors.scss";

  .header {
    height: $header-height;
    z-index: 102;
    font-size: 17px;

    &._white {

      .hover__underline::before {  background: #fff; }

      .header__links li a {
        color: #fff !important;
        &.router-link-exact-active:after {
          background-color: #fff;
        }
      }
    }

    > div {
      height: 100%;
      margin: 0 32px;

      .hover__underline::before {
        height: 2px;
        background: #000;
        transition: all 0.3s ease-in-out;
      }

      .header__links {
        display: flex;

        li {
          margin-right: 16px;

          a {
            font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
            color: $meta-gray !important;
            white-space: nowrap;
            position: relative;

            &.router-link-exact-active:after {
              content: "";
              position: absolute;
              height: 2px;
              background-color: $meta-gray;
              left: 0;
              right: 0;
              bottom: -2px;
            }
          }
        }
      }
    }
  }

  .header__links-container {
    display: inherit;

    @media (max-width: 1050px) {
      display: none;
    }
  }

  .header__links-dropdown-container {
    margin-right: 8px;
    display: none;

    @media (max-width: 1050px) {
      display: inherit;
    }
  }

</style>
