export const RECENT_DOCUMENT_TYPES = {
	SURVEY: 'SURVEY',
	COLLATERAL: 'COLLATERAL',
	METHODOLOGY: 'METHODOLOGY',
	REPORT: 'REPORT'
};

export const TRENDING_TYPES = {
	TOPIC: 'TOPIC',
	SURVEY: 'SURVEY',
	FILE: 'FILE'
};

export const ANALYTIC_TYPES = {
	COLLATERAL_DOWNLOAD: 'COLLATERAL_DOWNLOAD',

	VISIT_BY_DEPARTMENT: 'VISIT_BY_DEPARTMENT',
	DOWNLOAD_BY_DEPARTMENT: 'DOWNLOAD_BY_DEPARTMENT',

	TIME_SPENT_PER_COUNTRY: 'TIME_SPENT_PER_COUNTRY',
	TIME_SPENT_PER_PAGE: 'TIME_SPENT_PER_PAGE',

	FEDERATED_SEARCH_FILTERS: 'FEDERATED_SEARCH_FILTERS',
	FEDERATED_SEARCH_SURVEYS: 'FEDERATED_SEARCH_SURVEYS',
	EPLORE_PAGE_FILTERS: 'EPLORE_PAGE_FILTERS',
	EPLORE_PAGE_SURVEYS: 'EPLORE_PAGE_SURVEYS',
	ADDED_TALKING_POINTS: 'ADDED_TALKING_POINTS'
};
