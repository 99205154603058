<template>
	<v-menu open-on-click :attach="`#js-header-links-${randomToken}`" :nudge-width="70">

		<template v-slot:activator="{ on, attrs }">
			<v-btn :ripple="false" icon elevation="0" :color="isWhite ? 'white' : 'metaGray'" class="menu" v-bind="attrs" v-on="on">
				<span class="d-flex align-center justify-content-center avatar" :class="{'white--text': isWhite}">

					<v-img
						class="rounded-circle flex-grow-0 mr-2"
						:class="{ 'bg-orange': !user.image}"
						width="20"
						height="20"
						:src="user.image && `data:image/*;base64,${user.image}`"
					></v-img>

					{{ userFirstname }}

					<v-icon dense small>
						{{ attrs['aria-expanded'] === 'true' ? 'expand_less' : 'expand_more' }}
					</v-icon>
				</span>
			</v-btn>
		</template>

		<ul class="menu-list">
			<li class="menu-list_username">
				<router-link to="/">{{ user.name }}</router-link>
			</li>
			<li>
				<router-link to="/favorites?tab=filter-selections">Saved filters</router-link>
			</li>
			<li>
				<router-link to="/favorites?tab=surveys">Favorite surveys</router-link>
			</li>
			<li>
				<router-link to="/user-settings">User settings</router-link>
			</li>
			<li v-if="!!user.admin">
				<router-link to="/site-analytics">Site analytics</router-link>
			</li>
			<li @click="signOut()">
				Sign-out
			</li>
		</ul>

	</v-menu>
</template>

<script>

const { mapState, mapGetters } = require('vuex');

export default {

	name: 'HeaderUserDropdown',

	props: {
		isWhite: {
			type: Boolean,
			default: false
		},
		randomToken: {
			type: String,
			required: true
		}
	},

	methods: {
		async signOut () {
			window.location.href = '/security/signout';
		}
	},

	computed: {
		...mapState({
			user: (state) => state.general.user
		}),
		...mapGetters('general', [
			'userFirstname'
		])
	}
};

</script>

<style lang="scss" scoped>
  @import "@/scss/_colors.scss";

  .avatar {
    padding: 3px;
    font-family: 'Optimistic Display', 'Noto Sans Khmer', sans-serif;
    font-weight: inherit;

    .bg-orange {
      background-color: #FBAB7E;
      background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    }
  }

  .v-menu__content {
    left: unset !important;
    right: 0 !important;
    top: 36px !important;
    width: 200px !important;
  }

  .menu {
		color: $meta-gray !important;
		white-space: nowrap;
		height: auto !important;
		width: auto !important;
		text-transform: none;
		background-color: transparent !important;
    border-radius: 6px;

    &:focus::before {
      opacity: 0 !important;
    }

		&:hover {
			background-color: none;
		}

		span {
			border-radius: 6px;
			background-color: rgba(203,210,217,0.4); /* $gray-300 */
      letter-spacing: initial;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}



.menu-list {
	background-color: $gray-100;
  z-index: 999;

	li {
    z-index: 999;
		height: 30px;
		padding: 4px 8px;
		font-size: 15px;
		border-bottom: 1px solid $gray-100;
    color: $meta-gray !important;
    cursor: pointer;

    &:first-child {
      border-bottom: 1px solid $gray-300;
    }

		a {
			color: $meta-gray !important;
		}

	}
	&-action {
		cursor: pointer;
	}
}

</style>
