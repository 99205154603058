import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

export default {
	async install (Vue, _) {
		const sentryConfigFile = await fetch('/config/sentry.json');
		const sentryConfigJson = await sentryConfigFile.json();

		if (sentryConfigJson.enabled) {
			Sentry.init({
				Vue,
				autoSessionTracking: true,
				dsn: sentryConfigJson.dsn,
				environment: sentryConfigJson.environment,
				integrations: [
					new Integrations.BrowserTracing({
						tracingOrigins: [
							sentryConfigJson.tracing.origin,
							/^\//
						]
					})
				],
				tracesSampleRate: sentryConfigJson.tracing.sampleRate
			});
		}
	}
};
