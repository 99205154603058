import axios from 'axios';
const api = require('@/utils/api');
const { RECENT_DOCUMENT_TYPES, TRENDING_TYPES } = require('@/constants/types');

const state = () => ({
	markets: [],
	selectedMarkets: [],

	surveyIds: [],

	nonSurveyRespondentTypes: [],
	selectedNonSurveyRespondentTypes: [],

	surveyRespondentTypes: [],
	selectedSurveyRespondentTypes: [],

	loadingFiles: true,
	files: [],
	userRecentFiles: [],
	trendingFiles: [],
	search: '',
	showDownloadError: false,

	previewFile: null
});

const getters = {
	filesWithCountryIds (state) {
		return state.files.map((file) => ({
			...file,
			countryIds: (file.countries || [])
				.concat([file.country])
				.filter((c) => c)
				.map((c) => c.id)
		}));
	},
	filesFilteredByRespondentTypes (state, getters) {
		const selectedNonSurveyRespondentTypeLabels = state.selectedNonSurveyRespondentTypes.map((r) => r.label);
		const selectedSurveyRespondentTypeLabels = state.selectedSurveyRespondentTypes.map((r) => r.label);
		return getters.filesWithCountryIds.filter((f) =>
			(selectedNonSurveyRespondentTypeLabels.length ? (!state.surveyIds.includes(f.surveyId) && selectedNonSurveyRespondentTypeLabels.includes(f.respondentType)) : true) &&
      (selectedSurveyRespondentTypeLabels.length ? (state.surveyIds.includes(f.surveyId) && selectedSurveyRespondentTypeLabels.includes(f.respondentType)) : true)
		);
	},
	filteredFiles (state, getters) {
		if (state.loadingFiles) {
			return new Array(12);
		}

		const searchTerm = state.search?.trim().toUpperCase() || '';
		const selectedMarketIds = state.selectedMarkets.map((m) => m.id);

		return getters.filesFilteredByRespondentTypes.filter((f) =>
			f.name.toUpperCase().includes(searchTerm) &&
      (selectedMarketIds.length ? f.countryIds.some((id) => selectedMarketIds.includes(id)) : true)
		);
	},
	activeNonSurveyRespondentTypes (state, getters) {
		if (state.selectedSurveyRespondentTypes.length) {
			return [];
		}
		return state.nonSurveyRespondentTypes;
	},
	activeSurveyRespondentTypes (state, getters) {
		if (state.selectedNonSurveyRespondentTypes.length) {
			return [];
		}
		return state.surveyRespondentTypes;
	},
	activeMarkets (state, getters) {
		const countryIds = getters.filesFilteredByRespondentTypes.map((f) => f.countryIds).flat();
		const uniqueCountryIds = [...new Set(countryIds)];
		return state.markets.filter((market) => uniqueCountryIds.includes(market.id));
	}
};

const mutations = {
	setMarkets (state, data) {
		state.markets = data;
	},
	setSelectedMarkets (state, data) {
		state.selectedMarkets = data;
	},

	setSurveyIds (state, data) {
		state.surveyIds = data;
	},

	setNonSurveyRespondentTypes (state, data) {
		state.nonSurveyRespondentTypes = data;
	},
	setSelectedNonSurveyRespondentTypes (state, data) {
		state.selectedNonSurveyRespondentTypes = data;
	},

	setSurveyRespondentTypes (state, data) {
		state.surveyRespondentTypes = data;
	},
	setSelectedSurveyRespondentTypes (state, data) {
		state.selectedSurveyRespondentTypes = data;
	},

	setLoadingFiles (state, data) {
		state.loadingFiles = data;
	},
	setFiles (state, data) {
		state.files = data;
	},
	setUserRecentFiles (state, data) {
		state.userRecentFiles = data;
	},
	setTrendingFiles (state, data) {
		state.trendingFiles = data;
	},
	setSearch (state, data) {
		state.search = data;
	},
	setShowDownloadError (state, data) {
		state.showDownloadError = data;
	},

	setPreviewFile (state, file) {
		state.previewFile = file;
	}
};

const actions = {
	async loadFiltersForFiles ({ state, commit }) {
		const data = await api.loadFiltersForFiles();

		if (data) {
			const { surveyIds, nonSurveyRespondentTypes, surveyRespondentTypes, markets } = data;
			commit('setSurveyIds', surveyIds);
			commit('setNonSurveyRespondentTypes', nonSurveyRespondentTypes.map(helpers.createObjectFromLabel));
			commit('setSurveyRespondentTypes', surveyRespondentTypes.map(helpers.createObjectFromLabel));
			commit('setMarkets', markets);
		}
	},
	async loadCollateralMethodologyFiles ({ commit }) {
		commit('setLoadingFiles', true);
		try {
			commit('setFiles', await api.loadCollateralMethodologyFiles({}));
		} catch (err) {
			commit('setFiles', []);
		} finally {
			commit('setLoadingFiles', false);
		}
	},
	async loadUserRecentCollateralMethodologyFiles ({ commit }) {
		commit('setUserRecentFiles', await api.loadUserRecentDocuments([RECENT_DOCUMENT_TYPES.COLLATERAL, RECENT_DOCUMENT_TYPES.METHODOLOGY]));
	},
	async loadTrendingFiles ({ commit }) {
		const files = await api.loadMarketTrendings(TRENDING_TYPES.FILE) || [];
		commit('setTrendingFiles', files);
	},
	async storeCollateralAnalyticsInformations (file) {
		await api.storeCollateralAnalyticsInformations({
			surveyId: file.surveyId,
			country: file.country,
			type: file.type,
			subtype: file.subtype,
			timestamp: +(new Date())
		});
	},
	async downloadFile ({ commit }, file) {
		const URL = `/api/v1/static-file/download?fileKey=${file.key}`;

		try {

			const response = await axios.get(URL, { responseType: 'blob' });

			const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
			const link = document.createElement('a');

			link.href = url;
			link.setAttribute('download', `${file.name}.${file.extension.toLowerCase()}`);
			document.body.appendChild(link);
			link.click();

		} catch (e) {

			commit('setShowDownloadError', true);

		}
	},
	onSelectPreview ({ commit, dispatch }, file) {
		const action = file.type === 'Collateral' ? 'home/addRecentCollateralDocument' : 'home/addRecentMethodologyDocument';
		dispatch(action, file.fileId, { root: true });
		commit('setPreviewFile', file);
	},
	onSelectExport ({ dispatch }, file) {
		const action = file.type === 'Collateral' ? 'home/addRecentCollateralDocument' : 'home/addRecentMethodologyDocument';
		dispatch(action, file.fileId, { root: true });
		dispatch('downloadFile', file);
	}
};

const helpers = {
	createObjectFromLabel (label) {
		return { id: label.toLowerCase(), label };
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
